<template>
  <div class="user-invoice-section">
    <headerCustom></headerCustom>
    <div class="profile-section main-content">
      <div class="heading-sec">
        <div class="row">
          <div class="col-md-4 column">
            <div class="heading-profile">
              <h3>USER</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- title section end -->
      <div>
        <b-container>
          <b-row>
            <b-col md="10">
              <div class="user-profile-invoice-edit" v-if="user && user.name">
                <div class="d-flex align-items-center pt-3">
                  <div class="title">Invoice Info</div>
                  <div class="ml-auto">
                    <b-button
                      class="btn btn-sm bg-gray shadow-none border-0 mr-4 px-5"
                      :to="'/viewUserDetail/' + id"
                      >Back</b-button
                    >
                  </div>
                </div>
                <div class="border-bottom mx-4"></div>
                <div class="invoice-form-section">
                  <b-row>
                    <b-col md="5">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            Name
                          </label>
                          <b-form-input
                            id="hotelName"
                            aria-describedby="input-hotelName-live-feedback"
                            v-model="user.name"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-hotelName-live-feedback">
                            Name of hotel is required.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="5">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            Company Name
                          </label>
                          <b-form-input
                            id="cmpnyName"
                            aria-describedby="input-companyName-live-feedback"
                            v-model="user.invoice.companyName"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-companyName-live-feedback">
                            Company Name is required.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="9">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            Address
                          </label>
                          <div>
                            <textarea
                              id="hotelName"
                              class="form-control"
                              rows="3"
                              cols="83"
                              aria-describedby="input-address1-live-feedback"
                              v-model="user.invoice.address1"
                            ></textarea>
                            <b-form-invalid-feedback id="input-address1-live-feedback">
                              Address is required.
                            </b-form-invalid-feedback>
                          </div>
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            Country
                          </label>
                          <b-form-select
                            aria-describedby="input-rcountry-live-feedback"
                            v-model="user.invoice.rcountry"
                            :options="countries"
                            @input="setState('rstate', user.invoice.rcountry)"
                          ></b-form-select>
                          <b-form-invalid-feedback id="input-rcountry-live-feedback">
                            Country is required.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="3">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            State
                          </label>
                          <b-form-select
                            aria-describedby="input-rstate-live-feedback"
                            v-model="user.invoice.rstate"
                            :options="rstate"
                            @input="setCity('rcity', user.invoice.rstate)"
                          ></b-form-select>
                          <b-form-invalid-feedback id="input-rstate-live-feedback">
                            State is required.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="3">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            City
                          </label>
                          <b-form-select
                            aria-describedby="input-rcity-live-feedback"
                            v-model="user.invoice.rcity"
                            :options="rcity"
                          ></b-form-select>
                          <b-form-invalid-feedback id="input-rcity-live-feedback">
                            City is required.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="3">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            Pincode
                          </label>
                          <b-form-input
                            id="pincode"
                            type="number"
                            aria-describedby="input-rpincode-live-feedback"
                            v-model="user.invoice.rpincode"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-rpincode-live-feedback">
                            Pincode is required.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="9">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            Mailing Address
                          </label>
                          <div>
                            <textarea
                              id="hotelName"
                              class="form-control"
                              rows="3"
                              cols="83"
                              aria-describedby="input-address2-live-feedback"
                              v-model="user.invoice.address2"
                            ></textarea>
                            <b-form-invalid-feedback id="input-address2-live-feedback">
                              Address is required.
                            </b-form-invalid-feedback>
                          </div>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="3">
                      <div class="form-check">
                        <input
                          id="exampleCheck1"
                          type="checkbox"
                          @click="sameAsAbove()"
                          class="form-check-input"
                        />
                        <div class="form-check-label" for="exampleCheck1">
                          Same as Above
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            Country
                          </label>
                          <b-form-select
                            aria-describedby="input-bcountry-live-feedback"
                            v-model="user.invoice.bcountry"
                            :options="countries"
                            @input="setState('bstate', user.invoice.bcountry)"
                          ></b-form-select>
                          <b-form-invalid-feedback id="input-bcountry-live-feedback">
                            Country is required.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="3">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            State
                          </label>
                          <b-form-select
                            aria-describedby="input-bstate-live-feedback"
                            v-model="user.invoice.bstate"
                            :options="bstate"
                            @input="setCity('bcity', user.invoice.bstate)"
                          ></b-form-select>
                          <b-form-invalid-feedback id="input-bstate-live-feedback">
                            State is required.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="3">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            City
                          </label>
                          <b-form-select
                            v-model="user.invoice.bcity"
                            :options="bcity"
                          ></b-form-select>
                          <b-form-invalid-feedback id="input-bcity-live-feedback">
                            City is required.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="3">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            Pincode
                          </label>
                          <b-form-input
                            id="pincode"
                            type="number"
                            v-model="user.invoice.bpincode"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-bpincode-live-feedback">
                            Pincode is required.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            Pan Card
                          </label>
                          <b-form-input
                            id="panCard"
                            type="text"
                            maxlength="10"
                            v-model="user.invoice.panCardNo"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            GST Certificate
                          </label>
                          <b-form-input
                            id="gstCert"
                            type="text"
                            maxlength="15"
                            v-model="user.invoice.gstNo"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <div class="upload-btn-section">
                        <b-input-group class="input-group">
                          <b-form-file
                            v-model="gstDoc"
                            placeholder="Choose a file..."
                            drop-placeholder="Drop file here..."
                          ></b-form-file>
                          <b-input-group-append>
                            <b-button
                              class="career-btn"
                              size="sm"
                              @click="uploadImage('gstCertificate', gstDoc)"
                              >Upload</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </b-col>
                    <b-col md="2" v-for="image1 in gstImg" :key="image1">
                      <div class="icon-section">
                        <font-awesome-icon
                          :icon="['fas', 'times']"
                          class="red font-12"
                          @click="removeGst(image1)"
                        />
                      </div>
                      <img :src="image1" alt="your image" class="img-fluid images ml-3" />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <div>
                        <b-form-group>
                          <label class="font-14">
                            TRN Certificate
                          </label>
                          <b-form-input
                            id="trnCert"
                            type="text"
                            maxlength="15"
                            v-model="user.invoice.trn"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <div class="upload-btn-section">
                        <b-input-group class="input-group">
                          <b-form-file
                            v-model="trnDoc"
                            placeholder="Choose a file..."
                            drop-placeholder="Drop file here..."
                          ></b-form-file>
                          <b-input-group-append>
                            <b-button
                              class="career-btn"
                              size="sm"
                              @click="uploadImage('trnCertificate', trnDoc)"
                              >Upload</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </b-col>
                    <b-col md="2" v-for="image2 in trnImg" :key="image2">
                      <div class="icon-section">
                        <font-awesome-icon
                          :icon="['fas', 'times']"
                          class="red font-12"
                          @click="removeTrn(image2)"
                        />
                      </div>
                      <img :src="image2" alt="your image" class="img-fluid images ml-3" />
                    </b-col>
                  </b-row>
                  <div class="pt-5">
                    <b-row>
                      <b-col md="3">
                        <div class="invoice-save ">
                          <b-button
                            class="btn btn-success btn-sm shadow-none border-0 "
                            @click="saveInvoice()"
                            >Save</b-button
                          >
                        </div>
                      </b-col>
                      <b-col md="3">
                        <div class="invoice-edit">
                          <b-button
                            class="btn btn-sm bg-danger shadow-none border-0"
                            to="/viewUserList"
                            >Cancel</b-button
                          >
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import img1 from "@/assets/images/logo.png";
import headerCustom from "@/components/Header.vue";
import apiservice from "@/service/apiservice";
import allCountries from "@/js/country";
import allStates from "@/js/state";
import allCities from "@/js/city";

export default {
  components: {
    headerCustom
  },
  data() {
    return {
      title: "Invoice",
      imgFile: null,
      imgFile1: null,
      imgFile2: null,
      gstDoc: null,
      trnDoc: null,
      panDoc: null,
      url: null,
      panImg: [],
      gstImg: [],
      trnImg: [],
      hotelName: {
        name: ""
      },
      user: {},
      id: this.$route.params.id,
      checked: true,
      invoice: {
        companyName: "",
        rcity: "",
        rpincode: "",
        rstate: "",
        rcountry: "",
        address1: "",
        address2: "",
        bcity: "",
        bpincode: "",
        bstate: "",
        bcountry: "",
        panCardNo: "",
        gstNo: "",
        trn: "",
        panCard: [],
        gstCertificate: [],
        trnCertificate: [],
        selectedRcountry: null
      },
      countries: [],
      state: [],
      city: [],
      allStates: [],
      allCities: [],
      rstate: [],
      bstate: [],
      rcity: [],
      bcity: []
    };
  },
  validations: {
    invoice: {
      companyName: {
        required
      },
      rcity: {
        required
      },
      rpincode: {
        required
      },
      rstate: {
        required
      },
      address1: {
        required
      },
      address2: {
        required
      },

      rcountry: {
        required
      },
      bcity: {
        required
      },
      bpincode: {
        required
      },
      bstate: {
        required
      },
      bcountry: {
        required
      },
      panCardNo: {
        minLength: minLength(10)
      },
      gstNo: {
        minLength: minLength(15)
      }
    },
    hotelName: {
      name: {
        required
      }
    }
  },
  created() {
    this.countries = allCountries;
    this.allStates = allStates;
    this.allCities = allCities;
    this.getUserInfo(this.id);
  },
  methods: {
    getUserInfo(id) {
      apiservice.getUserProfile({ id: this.id }, data => {
        if (data.status === 200) {
          this.user = data.data;
          this.panImg = this.user.invoice.panCard;
          this.gstImg = this.user.invoice.gstCertificate;
          this.trnImg = this.user.invoice.trnCertificate;
          this.setCountryCityState();
        } else {
    
        }
      });
    },
    removeGst(image) {
      this.gstImg.pop(image);
    },
    removeTrn(image) {
      this.trnImg.pop(image);
    },
    selectedCountryCityState() {
      const rcountry = this.countries.find(x => this.user.invoice.rcountry == x.value);
      if (rcountry) {
        this.user.invoice.rcountry = rcountry.text;
      }
      const rstate = this.allStates.find(x => this.user.invoice.rstate == x.id);
      if (rstate) {
        this.user.invoice.rstate = rstate.name;
      }
      const rcity = this.allCities.find(x => this.user.invoice.rcity == x.id);
      if (rcity) {
        this.user.invoice.rcity = rcity.name;
      }
      const bcountry = this.countries.find(x => this.user.invoice.bcountry == x.value);
      if (bcountry) {
        this.user.invoice.bcountry = bcountry.text;
      }
      const bstate = this.allStates.find(x => this.user.invoice.bstate == x.id);
      if (bstate) {
        this.user.invoice.bstate = bstate.name;
      }
      const bcity = this.allCities.find(x => this.user.invoice.bcity == x.id);
      if (bcity) {
        this.user.invoice.bcity = bcity.name;
      }
    },
    setState(name, value) {
      this[name] = [];
      this.allStates.forEach(x => {
        if (x.country_id == value) {
          this[name].push({
            text: x.name,
            value: x.id
          });
        }
      });
    },
    setCity(name, value) {
      this[name] = [];
      this.allCities.forEach(x => {
        if (x.state_id == value) {
          this[name].push({
            text: x.name,
            value: x.id
          });
        }
      });
    },
    saveInvoice() {
      this.selectedCountryCityState();
      apiservice.updateUserProfile({ user: this.user }, data => {
        if (data.status === 200) {
          this.$toaster.success("Details updated.");
          this.getUserInfo(this.id);
        } else {
          this.$toaster.error("Not saved.");
        }
      });
    },
    setCountryCityState() {
      const rcountry = this.countries.find(x => this.user.invoice.rcountry == x.text);
      if (rcountry) {
        this.setState("rstate", rcountry.value);
        this.user.invoice.rcountry = rcountry.value;
      }
      const rstate = this.rstate.find(x => this.user.invoice.rstate == x.text);
      if (rstate) {
        this.setCity("rcity", rstate.value);
        this.user.invoice.rstate = rstate.value;
      }
      const rcity = this.rcity.find(x => this.user.invoice.rcity == x.text);
      if (rcity) {
        this.user.invoice.rcity = rcity.value;
      }
      const bcountry = this.countries.find(x => this.user.invoice.bcountry == x.text);
      if (bcountry) {
        this.setState("bstate", bcountry.value);
        this.user.invoice.bcountry = bcountry.value;
      }
      const bstate = this.bstate.find(x => this.user.invoice.bstate == x.text);
      if (bstate) {
        this.setCity("bcity", bstate.value);
        this.user.invoice.bstate = bstate.value;
      }
      const bcity = this.bcity.find(x => this.user.invoice.bcity == x.text);
      if (bcity) {
        this.user.invoice.bcity = bcity.value;
      }
    },
    sameAsAbove() {
      this.user.invoice.address2 = this.checked ? this.user.invoice.address1 : "";
      this.user.invoice.bcity = this.checked ? this.user.invoice.rcity : "";
      this.user.invoice.bcountry = this.checked ? this.user.invoice.rcountry : "";
      this.user.invoice.bstate = this.checked ? this.user.invoice.rstate : "";
      this.checked = !this.checked;
    },
    uploadImage(name, file) {
      if (!file) {
        this.$toaster.error("Add a file.");
        return;
      }
      const fd = new FormData();
      fd.append("file", file, file.name);
      apiservice.awscoverImageUpload(fd, awsResponse => {
        if (awsResponse.status === 200) {
          var arr = [];
          arr.push(awsResponse.data.file);
          this.user.invoice[name] = arr;
          this.$toaster.success("Image Save");
        } else {
          this.$toaster.danger("Image Not Save");
          // this.invoice.panCard = "";
        }
      });
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
.user-invoice-section {
  .profile-section {
    .user-profile-invoice-edit {
      background: $white;
      .title {
        color: $pink;
        font-size: $font-24;
        padding: 0.5rem 1.4rem;
      }
      .invoice-form-section {
        padding: 1.5rem 1.4rem;
        .upload-btn-section {
          padding: 1.9rem 0rem;
        }
        .invoice-edit,
        .invoice-save {
          .btn-secondary {
            width: 100% !important;
          }
        }
      }
      .form-control {
        height: calc(1em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
      }
      .custom-select {
        height: calc(1em + 0.75rem + 2px);
        box-shadow: none;
      }
      .form-check {
        padding-top: 2.4rem;
      }
    }
  }
}
</style>
